<template>
  <div class="element materialShadow popUpScrollList">
    <div class="gallery sortbox">
      <list-filter-item type="districts" :item-renderer="itemRenderer"
                        v-model="filters.districts"/>
      <list-filter-item type="channels" :item-renderer="itemRenderer"
                        v-model="filters.channels"/>
      <list-filter-item type="customers" :item-renderer="customerRenderer"
                        v-model="filters.customers"
                        :subset="filters.users"
                        :subsetQuery="customerSubsetQuery"/>
      <dd v-if="menuViewProducts">
        <list-filter-item type="products" :item-renderer="itemRenderer"
                        v-model="filters.products"/>
      </dd>
      <dd v-if="menuViewCampaigns">
        <list-filter-item type="campaigns" :item-renderer="itemRenderer"
                        v-model="filters.campaigns"/>
      </dd>
      <list-filter-item type="chains" :item-renderer="chainRenderer"
                        v-model="filters.chains"/>
      <list-filter-item v-if="currentPage !== Views.MyPosts"
                        type="users" :item-renderer="userRenderer"
                        v-model="filters.users"
                        :subset="filters.districts"
                        :subsetQuery="userSubsetQuery"/>
      <dd v-if="filterViewUserRoles && currentPage !== Views.MyPosts">
        <list-filter-item type="userRoles" :item-renderer="itemRenderer"
                        v-model="filters.userRoles"/>
      </dd>

      <div class="filter_time filterbox">
        <b class="ThemeAppFilterGroupName">{{x('dateDashFrom')}}
          <input type="text" name="date-from" placeholder="DD-MM-YYYY"
                 class="Theme_C1_BaseColor date-picker"
                 style="display:inline-block;" data-dateformat="DD-MM-YYYY"
                 data-headerformat="MMM YYYY"
                 readonly ref="dateFrom" :value="filters.dateFrom">
        </b>
        <b class="ThemeAppFilterGroupName">{{x('dateDashTo')}}
          <input type="text" name="date-to" placeholder="DD-MM-YYYY"
                 class="Theme_C1_BaseColor date-picker"
                 style="display:inline-block;" data-dateformat="DD-MM-YYYY"
                 data-headerformat="MMM YYYY"
                 readonly ref="dateTo" :value="filters.dateTo">
        </b>
      </div>

      <button class="lsc-button lsc-rounded Theme_C3_BG Theme_C3_TXT Theme_C3_Border"
              @click="applyFilter"
              style="width:100%; margin-bottom: 10px;">
        <icon name="fa-filter"/>{{x('updateFilter')}}
      </button>
      <button class="lsc-button lsc-rounded Theme_C4_BG Theme_C4_TXT Theme_C4_Border "
              @click="clearFilter"
              style="width:100%;">
        <icon name="fa-trash-o"/>{{x('resetFilter')}}
      </button>
      <br><br>
    </div>
  </div>
</template>

<script>
  import { defineAsyncComponent } from 'vue';
  import { mapState } from 'vuex';
  import { httpPost } from '@/classes/httpHelper';
  import ComponentEventNames from '@/enums/component-event-names';
  import Views from '@/enums/views';
  import {
    itemRenderer, chainRenderer, userRenderer, customerRenderer,
  } from '@/classes/item-renderers';
  import translate from '../Mixins/Translate';
  import featureNames from '../../enums/feature-names';

  /**
   * This is the component showing the app list filter.
   */
  export default {
    name: 'list-filter',
    emits: [
      ComponentEventNames.didLoad,
      ComponentEventNames.close,
    ],
    components: {
      'list-filter-item': defineAsyncComponent(() => import('./ListFilterItem.vue')),
    },
    mixins: [translate],
    store: globalVue.$store,
    data() {
      return {
        filters: {
          districts: [],
          channels: [],
          customers: [],
          products: [],
          campaigns: [],
          chains: [],
          users: [],
          userRoles: [],
          dateFrom: '',
          dateTo: '',
        },
        featureNames,
        districtTimeoutId: null,
        userTimeoutId: null,
        itemRenderer,
        chainRenderer,
        userRenderer,
        customerRenderer,
        Views,
      };
    },
    computed: {
      menuViewProducts() { return this.config.features[featureNames.menuViewProducts]; },
      menuViewCampaigns() { return this.config.features[featureNames.menuViewCampaigns]; },
      filterViewUserRoles() { return this.config.features[featureNames.filterViewUserRoles]; },
      ...mapState(['config', 'user', 'currentPage']),
      ...mapState({
        vuexFilter: 'filter',
      }),
    },
    async mounted() {
      $(this.$refs.dateFrom).datePicker();
      $(this.$refs.dateTo).datePicker();
      this.$emit(ComponentEventNames.didLoad);

      await this.$nextTick();

      if (this.vuexFilter !== null) {
        this.filters = { ...this.vuexFilter };
      }
    },
    methods: {
      async userSubsetQuery(items) {
        const data = {
          districts: items,
        };
        return httpPost('users/indistricts', data);
      },
      async customerSubsetQuery(items) {
        const data = {
          users: items,
        };
        return httpPost('customers/forusers', data);
      },
      async applyFilter() {
        this.filters.dateFrom = this.$refs.dateFrom.value;
        this.filters.dateTo = this.$refs.dateTo.value;

        this.$store.commit('setFilter', this.filters);
        this.$emit('close');
      },
      clearFilter() {
        this.$store.commit('setFilter', null);
        this.$emit('close');
      },
    },
  };
</script>
